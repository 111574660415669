
//==========  Import modules  ==========//

// import our global modules
@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

// import our local modules
@import '../modules/local/typography/_index';


//==========  Begin styles  ==========//


// Reset
.cms-toolbar {
	@extend .hidden;
}

.mobile-ui {
	@extend .hidden;
}


// Print styles
* {
	color: $color-black;
	background: none;
}

.wrapper {
	padding: 2rem;
}

.header {

	.menu-toggle,
	.nav {
		@extend .hidden;
	}

	.inner {

		* {
			@extend .hidden;
		}

		.company-logo-link,
		.company-logo {
			display: block;
			max-width: 250px;
		}

		.company-logo {
			margin-bottom: 3rem;
		}
	}
}

.hero {

	.inner {

		* {
			@extend .hidden;
		}

		.profile-image-wrapper,
		.profile-image {
			display: block;
		}

		.profile-image-wrapper {
			margin-bottom: 3rem;
		}
	}
}

.column {

	&.right,
	&.left {
		@extend .hidden;
	}

	&.centre {

		.block-text {
			display: block;
		}

		.component-sign-off,
		.crumbtrails {
			@extend .hidden;
		}
	}
}

.pre-footer {
	@extend .hidden;
}

.search-container {
	@extend .hidden;
}

.footer-meta {
	padding: $block-padding;
	text-align: center;
}

.office {
	padding: $block-padding;
}

.social-icons,
.footer-links,
.search-bar,
.footer-sra {
	@extend .hidden;
}

.cookies {
	@extend .hidden;
}

#ccc-notify {
	display: none !important;
}


.type-profile {
	.column {
		&.right {
			display: block;

			.profile-image {
				max-width: 300px;
			}

			.profile-name {
				font-size: $h3;
				font-weight: $font-bold;
			}

			.rle-service-accreditations {
				@extend .hidden;
			}
		}
	}
}
