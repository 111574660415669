
//==========  Buttons  ==========//

// set our default button height
// keeps buttons consistent across site and easier to align
$button-height: 50px;

// set the left|right padding for buttons
// keeps buttons consistent across site
// this also allows us to create and align icon buttons more easily
// (a simple calc like calc($button-padding + $icon-width); )
$button-padding: ($block-padding * 2);

// define how rounded buttons should be
// default here is to inherit the global $border-radius value, for slightly rounded
// for completely rounded buttons, set value to be $button-height
$button-rounded: $border-radius;
// $button-rounded: $button-height;
