
// Default .button class
.button {
    @extend %button;
    display: inline-block;
    height: $button-height;
    padding: 0 $button-padding;
    line-height: $button-height;


    &.brand {
        background: $color-primary;
        color: $color-white;

        .icon {
            fill: $color-white;
        }
    }

    &.alt {
        background: $color-secondary;
        color: $color-white;

        .icon {
            fill: $color-white;
        }
    }

    &.tone {
        background: $color-white;
        color: $color-primary;

        .icon {
            fill: $color-primary;
        }
    }


    &:hover {
        @extend %hover;

        .icon {
            fill: $color-white;
        }
    }

    &.to-brand {

        &:hover {
            background: $color-primary;
            color: $color-white;

            .icon {
                fill: $color-white;
            }
        }
    }

    &.to-alt {

        &:hover {
            background: $color-secondary;
            color: $color-white;

            .icon {
                fill: $color-white;
            }
        }
    }

    &.to-tone {

        &:hover {
            background: $color-white;
            color: $color-primary;

            .icon {
                fill: $color-primary;
            }
        }
    }

    
    // automatically position icons when creating buttons
    .icon {
        left: calc(#{$button-padding} - #{$icon-width * 0.5});
    }

    
    // apply a reset for original implentation of .button class
    // (was on parent element, now on link itself)
    a {
        color: inherit;
        transition: inherit;
    }
}


// button list
// (usually seen on /site/onlinepayment page)
.buttons {
    @extend %clear-fix;

    .button {
        margin: 0 0 ($block-padding * 0.5);
        
        @media #{$tabletXL} {
            @include fraction(2, $block-padding);
        }
    }
}

