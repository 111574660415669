
// Clear paragraph styles
p {
	margin: 0;
}

// Reset strong tags
strong {
	font-weight: $font-bold;
}

// Reset hr tags
hr {
	border: $border;
}

blockquote {
	border-left: 6px solid $color-lightgrey;
	margin: 0 0 $block-margin;
	padding: $site-padding 0 $site-padding ($site-padding * 1.5);

	cite {
		font-size: $h6 - .2;
		font-weight: $font-bold;
	}
}
